<template>
    <BlockTitle :block="block" />
    <div :class="block.properties.show_title ? 'pt-4' : ''">
        <ProductsByTag tag="top-seller" metals="gold,silver"/>
    </div>
</template>

<script setup>
import ProductsByTag from '../../Product/ProductsByTag.vue';
import BlockTitle from './BlockTitle.vue';
</script>

<script>
export default {
    props: {
        title: String,
        block: Object,
    },
    methods: {
    }
};
</script>
